var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container",attrs:{"id":"section-content"}},[_c('div',{staticClass:"bar-back"},[_c('router-link',{attrs:{"to":"/member/main"}},[_c('i',{staticClass:"fas fa-chevron-left"}),_vm._v(" หน้าหลัก")])],1),_c('div',{staticClass:"p-2 w-100 bg-light main-content align-self-stretch",staticStyle:{"min-height":"calc((100vh - 140px) - 50px)"}},[_vm._m(0),_c('div',{staticClass:"bgwhitealpha text-secondary shadow-sm rounded p-1 mb-5 xtarget col-lotto"},[_vm._m(1),_c('div',{staticClass:"form-row h-auto"},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('router-link',{staticClass:"btn btn-primary btn-block btn-larg",attrs:{"to":"/member/request/all","tag":"button"}},[_vm._v(" สถานะการเติมเงิน ")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0"},[_c('div',{staticClass:"lotto-title"},[_c('h4',[_c('i',{staticClass:"fas fa-donate"}),_vm._v(" แจ้งเติมเครดิต")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h6',{staticClass:"font-weight-normal"},[_c('span',{staticClass:"badge badge-pill badge-success font-weight-normal"},[_vm._v("STEP 4")]),_vm._v(" ยืนยันการเติมเงิน ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-sm-12 col-md-12 text-center"},[_c('h5',{staticClass:"font-weight-light text-success mb-0"},[_vm._v("แจ้งฝากเงิน")]),_c('h1',{staticClass:"font-weight-light text-success totalmoney"},[_vm._v("500.00")]),_c('span',{staticClass:"badge badge-secondary font-weight-light mr-1"},[_vm._v("เวลาแจ้งโอน")]),_c('span',[_c('i',{staticClass:"far fa-calendar-check"}),_vm._v(" "),_c('span',{staticClass:"transferdate mr-1"},[_vm._v("22/06/2021")]),_c('i',{staticClass:"far fa-clock"}),_vm._v(" "),_c('span',{staticClass:"transfertime"},[_vm._v("21:56")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-sm-12 col-md-6 py-1 fromacc"},[_c('div',{staticClass:"alert alert-primary",attrs:{"role":"alert"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-center"},[_c('h5',{staticClass:"text-success w-100 font-weight-light"},[_vm._v("โอนจากบัญชี")])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-3 col-sm-3 col-md-4 col-lg-3 pt-2"},[_c('img',{staticClass:"detail-bank rounded mybanklogo",staticStyle:{"background-color":"white"},attrs:{"src":require("@/assets/icon/bank.png"),"alt":"","width":"100%"}})]),_c('div',{staticClass:"col-9 col-sm-9 col-md-8 col-lg-5 pt-2"},[_c('h6',{staticClass:"numacc myaccdeposit"},[_vm._v("026-2-26614-3")]),_c('span',{staticClass:"badge badge-pill badge-secondary font-weight-normal"},[_vm._v("ชื่อบัญชี")]),_c('br'),_c('span',{staticClass:"myname"},[_vm._v("ไชยพงศ์ พิพิธธนโชติ")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-sm-12 col-md-6 py-1 toacc"},[_c('div',{staticClass:"alert alert-success",attrs:{"role":"alert"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-center"},[_c('h5',{staticClass:"text-success w-100 font-weight-light"},[_vm._v("โอนให้บัญชี")])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-3 col-sm-3 col-md-4 col-lg-3 pt-2"},[_c('img',{staticClass:"detail-bank rounded svbanklogo",staticStyle:{"background-color":"white"},attrs:{"src":require("@/assets/icon/bank.png"),"alt":"","width":"100%"}})]),_c('div',{staticClass:"col-9 col-sm-9 col-md-8 col-lg-5 pt-2"},[_c('h6',{staticClass:"numacc svaccdeposit"},[_vm._v("007-0-20012-2")]),_c('span',{staticClass:"badge badge-pill badge-secondary font-weight-normal"},[_vm._v("ชื่อบัญชี")]),_c('br'),_c('span',{staticClass:"svname"},[_vm._v("น.ส. อรอุมา ชินโชติ")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 mb-2 text-center"},[_c('small',[_vm._v("รายการของท่านถูกส่งไปยังระบบเรียบร้อยแล้ว กรุณารอไม่เกิน 3 นาที")]),_c('small',{staticClass:"text-primary"},[_c('a',{attrs:{"href":"https://lin.ee/3sdLSBGGU"}},[_vm._v("หากมีปัญหากรุณาติดต่อฝ่าย Support")])])])
}]

export { render, staticRenderFns }